import { AllContextualConstants } from "./contextual-media.constants";
import axios from 'axios';
import { config } from '../../../helpers'
import { formatDateDisplay, toFormData } from '../../../components/utilities/customFunctions'

const ROOT_URL = config.apiServer;
const ROOT_URL_API = ROOT_URL + "/api";

export const getDealType = (accountId, decarbonise = false, channel = "") => {
    return getDealTypes(accountId, "GET_DEAL_TYPE", decarbonise, channel)
}
export const getDealTypePop = (accountId) => {
    return getDealTypes(accountId, "GET_DEAL_TYPE_POP")
}


export const getDealTypes = (accountId, constant = "GET_DEAL_TYPE", decarbonise = false, channel = "") => {
    return (dispatch) => {
        dispatch(dealTypeloading(true));
        axios.get(`${ROOT_URL_API}/get-dealType?accountId=${accountId}&decarbonise=${decarbonise}&channel=${channel}`, { headers: { Authorization: config.authToken() } })
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch({
                        type: AllContextualConstants[constant],
                        payload: response.data.data
                    });
                }
                else {
                    dispatch({
                        type: AllContextualConstants[constant + "_ERROR"],
                        payload: "error"
                    });
                }
                dispatch(dealTypeloading(false));
            }).catch(() => {
                dispatch({
                    type: AllContextualConstants[constant + "_ERROR"],
                    payload: "error"
                });
                dispatch(dealTypeloading(false));
            })
    }
}

const dealTypeloading = (status) => {
    return {
        type: AllContextualConstants.DEAL_TYPE_LOADING,
        payload: status
    };
};

export const emptyDealType = () => {
    return (dispatch) => {
        dispatch({ type: AllContextualConstants.EMPTY_DEAL_TYPE, payload: "" });
    }
}
export const emptyDealTypePop = () => {
    return (dispatch) => {
        dispatch({ type: AllContextualConstants.EMPTY_DEAL_TYPE_POP, payload: "" });
    }
}

export const getDealTypeFormatCarousel = (dealTypeFormatId) => {
    return (dispatch) => {
        dispatch(dealTypeFormatCarouselLoading(true));
        axios.get(`${ROOT_URL_API}/format-carousel-dealTypeFormatId?dealTypeFormatId=${dealTypeFormatId}`, { headers: { Authorization: config.authToken() } })
            .then(response => {

                if (response.data && response.data.success) {
                    dispatch({
                        type: AllContextualConstants.GET_DEAL_TYPE_FORMAT_CAROUSEL,
                        payload: response.data.data
                    });
                }
                else {
                    dispatch({
                        type: AllContextualConstants.GET_DEAL_TYPE_FORMAT_CAROUSEL_ERROR,
                        payload: "error"
                    });
                }
                dispatch(dealTypeFormatCarouselLoading(false));
            }).catch(() => {
                dispatch({
                    type: AllContextualConstants.GET_DEAL_TYPE_FORMAT_CAROUSEL_ERROR,
                    payload: "error"
                });
                dispatch(dealTypeFormatCarouselLoading(false));
            })
    }
}

const dealTypeFormatCarouselLoading = (status) => {
    return {
        type: AllContextualConstants.DEAL_TYPE_FORMAT_CAROUSEL_LOADING,
        payload: status
    };
};

export const emptyDealTypeFormatCarousel = () => {
    return (dispatch) => {
        dispatch({ type: AllContextualConstants.EMPTY_DEAL_TYPE_FORMAT_CAROUSEL, payload: "" });
    }
}



export const saveSelectedDealTypeInfo = (values) => dispatch => {
    dispatch(loading(true));

    axios.post(`${ROOT_URL_API}/contextual/create-deal`,
        toFormData({

            accountId: typeof values.account == "object" ? values.account.id : values.account,
            name: values.name,
            dspId: typeof values.dsp == "object" ? values.dsp.network_id : values.dsp,
            dsp_buyer_id: typeof values.dsp_buyer_id == "object" ? values.dsp_buyer_id.buyerId : values.dsp_buyer_id,
            dealType: typeof values.selectedDealType == "object" ? values.selectedDealType.id : values.selectedDealType,
            is_mobile_app: typeof values.is_mobile_app == "object" ? values.is_mobile_app.value : values.is_mobile_app,
            addonId: values.addonIds || "",
            measure_emissions: values.measure_emissions,
            decarbonise: typeof values.decarbonise == "object" ? values.decarbonise.value : values.decarbonise,
            decarbonise_options: JSON.stringify(values.decarbonise_options),
            net_media_bugdet: values.net_media_bugdet || "",
            campaign_dates: getDates(values.campaign_dates),
            ...(values.cpm ? {cpm: values.cpm} : {})

        }), { headers: { Authorization: config.authToken() } })
        .then(response => {
            if (response.data && response.data.success) {

                dispatch({ type: AllContextualConstants.ADD_SELECTED_DEAL_TYPE_SUCCESS, payload: response.data.data.id });
            }
            else {
                dispatch({ type: AllContextualConstants.ADD_SELECTED_DEAL_TYPE_ERROR, payload: response.data.msg + "~" + Date.now() });
            }

            dispatch(loading(false));
        }).catch(error => {
            dispatch({ type: AllContextualConstants.ADD_SELECTED_DEAL_TYPE_ERROR, payload: "Error~" + Date.now() });
            dispatch(loading(false));
        })
}


export const updateSelectedDealTypeInfo = (values, id) => dispatch => {
    dispatch(loading(true));

    axios.post(`${ROOT_URL_API}/contextual/update-deal?id=${id}`,
        toFormData({
            accountId: typeof values.account == "object" ? values.account.id : values.account,
            name: values.name,
            dspId: typeof values.dsp == "object" ? values.dsp.network_id : values.dspId,
            dsp_buyer_id: typeof values.dsp_buyer_id == "object" ? values.dsp_buyer_id.buyerId : values.dsp_buyer_id,
            dealType: typeof values.selectedDealType == "object" ? values.selectedDealType.id : values.selectedDealType,
            is_mobile_app: typeof values.is_mobile_app == "object" ? values.is_mobile_app.value : values.is_mobile_app,
            addonId: values.addonIds || "",
            measure_emissions: values.measure_emissions,
            decarbonise: typeof values.decarbonise == "object" ? values.decarbonise.value : values.decarbonise,
            decarbonise_options: JSON.stringify(values.decarbonise_options),
            net_media_bugdet: values.net_media_bugdet || "",
            campaign_dates: getDates(values.campaign_dates),
            ...(values.cpm ? {cpm: values.cpm} : {})

        }), { headers: { Authorization: config.authToken() } })
        .then(response => {
            if (response.data && response.data.success) {

                dispatch({ type: AllContextualConstants.UPDATE_SELECTED_DEAL_TYPE_SUCCESS, payload: response.data.data.id + "~" + Date.now() });
            }
            else {
                dispatch({ type: AllContextualConstants.UPDATE_SELECTED_DEAL_TYPE_ERROR, payload: response.data.msg + "~" + Date.now() });
            }

            dispatch(loading(false));
        }).catch(error => {
            dispatch({ type: AllContextualConstants.UPDATE_SELECTED_DEAL_TYPE_ERROR, payload: "Error~" + Date.now() });
            dispatch(loading(false));
        })
}


export const updateDealPlan = (values, id) => dispatch => {
    dispatch(loading(true));

    axios.post(`${ROOT_URL_API}/contextual/update-deal-plan/${id}`,
        toFormData({
            net_media_bugdet: values.net_media_bugdet || "",
            campaign_dates: getDates(values.campaign_dates),
        }), { headers: { Authorization: config.authToken() } })
        .then(response => {
            if (response.data && response.data.success) {

                dispatch({ type: AllContextualConstants.UPDATE_SELECTED_DEAL_PLAN_SUCCESS, payload: response.data.data.id + "~" + Date.now() });
            }
            else {
                dispatch({ type: AllContextualConstants.UPDATE_SELECTED_DEAL_PLAN_ERROR, payload: response.data.msg + "~" + Date.now() });
            }

            dispatch(loading(false));
        }).catch(error => {
            dispatch({ type: AllContextualConstants.UPDATE_SELECTED_DEAL_PLAN_ERROR, payload: "Error~" + Date.now() });
            dispatch(loading(false));
        })
}



const loadingCheckToken = (status) => {
    return {
        type: AllContextualConstants.CHECK_TOKEN_LOADING,
        payload: status
    };
};
export const checkDealTokenExist = (id, ftt) => {
    return (dispatch) => {
        dispatch(loadingCheckToken(true));

        axios.get(`${ROOT_URL_API}/check_deal_token_exists?accountid=${id}&format_type_tier=${ftt}`, { headers: { Authorization: config.authToken() } })
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch({ type: AllContextualConstants.CHECK_TOKEN, payload: response.data });
                }
                else {
                    dispatch({ type: AllContextualConstants.CHECK_TOKEN_ERROR, payload: response.data ? response.data.msg : "Error" });
                }
                dispatch(loadingCheckToken(false));
            }).catch(() => {
                dispatch({ type: AllContextualConstants.CHECK_TOKEN_ERROR, payload: false });
                dispatch(loadingCheckToken(false));
            });
    };
}
export const emptyDealToken = () => dispatch => {
    dispatch({
        type: AllContextualConstants.CHECK_TOKEN,
        payload: ""
    });
}

export const getContextualById = (id, param = "") => {
    return (dispatch) => {
        dispatch(loading(true));

        axios.get(`${ROOT_URL_API}/contextual/deal-info?id=${id}&${param}`, { headers: { Authorization: config.authToken() } })
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch({ type: AllContextualConstants.GET_CONTEXUAL_MEDIA_AD_BY_ID, payload: response.data.data });
                }
                else {
                    dispatch({ type: AllContextualConstants.GET_CONTEXUAL_MEDIA_AD_BY_ID_ERROR, payload: response.data ? response.data.msg : "Error" });
                }
                dispatch(loading(false));
            }).catch(() => {
                dispatch({ type: AllContextualConstants.GET_CONTEXUAL_MEDIA_AD_BY_ID_ERROR, payload: false });
                dispatch(loading(false));
            });
    };
}
const getDates = (dates) => dates?.start ? JSON.stringify({start: formatDateDisplay(dates?.start), end: formatDateDisplay(dates?.end)}) : ""
export const emptyDealData = () => dispatch => {
    dispatch({
        type: AllContextualConstants.EMPTY_CONTEXUAL_MEDIA_AD_DATA,
        payload: ""
    });
}

const loading = (status) => {
    return {
        type: AllContextualConstants.SELECTED_CONTEXUAL_MEDIA_LOADING,
        payload: status
    };
};


export const getPreDefineMedias = (dealTypeId, decarbonise = "") => {
    return (dispatch) => {
        dispatch(preDefineMediasloading(true));
        //let url = `predefined_media_master`
        let url = `prebuilt_media_master`
        if(decarbonise){
            url = `g_prebuilt_media_master`
        }        
        axios.get(`${ROOT_URL_API}/${url}?dealTypeId=${dealTypeId}`, { headers: { Authorization: config.authToken() } })
            .then(response => {

                if (response.data && response.data.success) {
                    let array = response.data.data

                    let preBuilt =  array.filter(function (el) {
                        return !el.provider_id && !el.provider
                    });
                    
                    let preBehave = array.filter(function (el) {

                        if (el.provider_id && el.provider) {
                            el.topSites = response.data.topSites || "";
                            return true;
                        }
                        return false;

                    });

                    dispatch({
                        type: AllContextualConstants.GET_PRE_DEFINE_MEDIAS,
                        payload: {preDefineMedias:preBuilt, preDefineMediasBehave:preBehave }
                    });
                }
                else {
                    dispatch({
                        type: AllContextualConstants.GET_PRE_DEFINE_MEDIAS_ERROR,
                        payload: "error"
                    });
                }
                dispatch(preDefineMediasloading(false));
            }).catch(() => {
                dispatch({
                    type: AllContextualConstants.GET_PRE_DEFINE_MEDIAS_ERROR,
                    payload: "error"
                });
                dispatch(preDefineMediasloading(false));
            })
    }
}

const preDefineMediasloading = (status) => {
    return {
        type: AllContextualConstants.GET_PRE_DEFINE_MEDIAS_LOADING,
        payload: status
    };
};

export const emptyPreDefineMedias = () => {
    return (dispatch) => {
        dispatch({ type: AllContextualConstants.EMPTY_PRE_DEFINE_MEDIAS, payload: "" });
    }
}



export const getBespokeMedias = (dealTypeId, accountId, decarbonise = "") => {
    return (dispatch) => {
        dispatch(bespokeloading(true));
        axios.get(`${ROOT_URL_API}/bespoke_media_master?dealTypeId=${dealTypeId}&accountId=${accountId}&decarbonise=${decarbonise}`, { headers: { Authorization: config.authToken() } })
            .then(response => {

                if (response.data && response.data.success) {
                    dispatch({
                        type: AllContextualConstants.GET_BESPOKE_MEDIAS,
                        payload: response.data.data
                    });
                }
                else {
                    dispatch({
                        type: AllContextualConstants.GET_BESPOKE_MEDIAS_ERROR,
                        payload: "error"
                    });
                }
                dispatch(bespokeloading(false));
            }).catch(() => {
                dispatch({
                    type: AllContextualConstants.GET_BESPOKE_MEDIAS_ERROR,
                    payload: "error"
                });
                dispatch(bespokeloading(false));
            })
    }
}


export const changeBespokeStatus = (values) => dispatch => {
    dispatch(bespokeStatusLoading(true));

    axios.post(`${ROOT_URL_API}/change_bespoke_status/${values.id}`,
        toFormData({
            status: !values.status

        }), { headers: { Authorization: config.authToken() } })
        .then(response => {
            if (response.data && response.data.success) {

                dispatch({ type: AllContextualConstants.UPDATE_BESPOKE_STATUS_SUCCESS, payload: response.data.data.id + "~" + Date.now() });
            }
            else {
                dispatch({ type: AllContextualConstants.UPDATE_BESPOKE_STATUS_ERROR, payload: response.data.msg + "~" + Date.now() });
            }

            dispatch(bespokeStatusLoading(false));
        }).catch(error => {
            dispatch({ type: AllContextualConstants.UPDATE_BESPOKE_STATUS_ERROR, payload: "Error~" + Date.now() });
            dispatch(bespokeStatusLoading(false));
        })
}

const bespokeStatusLoading = (status) => {
    return {
        type: AllContextualConstants.UPDATE_BESPOKE_STATUS_LOADING,
        payload: status
    };
}

const bespokeloading = (status) => {
    return {
        type: AllContextualConstants.GET_BESPOKE_MEDIAS_LOADING,
        payload: status
    };
};

export const emptyBespokeMedias = () => {
    return (dispatch) => {
        dispatch({ type: AllContextualConstants.EMPTY_BESPOKE_MEDIAS, payload: "" });
    }
}


export const saveselectedMedias = (values, id, accountId = "", deOp, version = "v2", token = false) => dispatch => {
    dispatch(loading(true));
    axios.post(`${ROOT_URL_API}/save-selected-medias-${version}/${id}?accountId=${accountId}`,
        toFormData({
            contextual_media: JSON.stringify(token ? [] : values),
            decarbonise_options: JSON.stringify({...deOp}),
            use_existing_token: token
        }), { headers: { Authorization: config.authToken() } })
        .then(response => {
            if (response.data && response.data.success) {

                dispatch({ type: AllContextualConstants.ADD_SELECTED_MEDIAS_SUCCESS, payload: response.data.data.id });
            }
            else {
                dispatch({ type: AllContextualConstants.ADD_SELECTED_MEDIAS_ERROR, payload: response.data.msg + "~" + Date.now() });
            }

            dispatch(loading(false));
        }).catch(error => {
            dispatch({ type: AllContextualConstants.ADD_SELECTED_MEDIAS_ERROR, payload: "Error~" + Date.now() });
            dispatch(loading(false));
        })
}

export const getBespokeCategories = (dealTypeId, decarbonise = false, sortBy = "adRequests", sortValue = "desc") => {
    return (dispatch) => {
        dispatch(bespokeCategoriesloading(true));
        //axios.get(`${ROOT_URL_API}/category?isAvailableForBespoke=true&dealTypeId=${dealTypeId}&sortBy=${sortBy}&sortValue=${sortValue}`, { headers: { Authorization: config.authToken() } })
        axios.get(`${ROOT_URL_API}/${decarbonise ? "g_bespoke_dictionary" : "bespoke_dictionary"}?dealTypeId=${dealTypeId}&sortBy=${sortBy}&sortValue=${sortValue}`, { headers: { Authorization: config.authToken() } })
            .then(response => {

                if (response.data && response.data.success) {
                    dispatch({
                        type: AllContextualConstants.GET_BESPOKE_CATEGORIES,
                        payload: response.data.data
                    });
                }
                else {
                    dispatch({
                        type: AllContextualConstants.GET_BESPOKE_CATEGORIES_ERROR,
                        payload: "error"
                    });
                }
                dispatch(bespokeCategoriesloading(false));
            }).catch(() => {
                dispatch({
                    type: AllContextualConstants.GET_BESPOKE_CATEGORIES_ERROR,
                    payload: "error"
                });
                dispatch(bespokeCategoriesloading(false));
            })
    }
}

const bespokeCategoriesloading = (status) => {
    return {
        type: AllContextualConstants.GET_BESPOKE_CATEGORIES_LOADING,
        payload: status
    };
};

export const emptyBespokeCategories = () => {
    return (dispatch) => {
        dispatch({ type: AllContextualConstants.EMPTY_BESPOKE_CATEGORIES, payload: "" });
    }
}



export const saveBespokeMedia = (values) => dispatch => {
    dispatch(loading(true));
    axios.post(`${ROOT_URL_API}/bespoke_media_master`,
        toFormData({
            accountId: values.accountId,
            name: values.name,
            card: values.card,
            description: values.description,
            estimated_emissions: values.estimated_emissions,
            decarbonise: values.decarbonise,
            topSites: values.topSites,
            uniqueVisitors: values.uniqueVisitors,
            adRequests: values.adRequests,
            audienceFact: values.audienceFact,
            audienceBehavior: values.audienceBehavior,
            dealTypeId: values.dealTypeId,
            categoryIds: values.categoryIds,
            bespoke_status: values.bespoke_status,
            isCustomised: false
        }), { headers: { Authorization: config.authToken() } })
        .then(response => {
            if (response.data && response.data.success) {

                dispatch({ type: AllContextualConstants.ADD_BESPOKE_MEDIA_SUCCESS, payload: response.data.data.id });
            }
            else {
                dispatch({ type: AllContextualConstants.ADD_BESPOKE_MEDIA_ERROR, payload: response.data.msg + "~" + Date.now() });
            }

            dispatch(loading(false));
        }).catch(error => {
            dispatch({ type: AllContextualConstants.ADD_BESPOKE_MEDIA_ERROR, payload: "Error~" + Date.now() });
            dispatch(loading(false));
        })
}


export const updateBespokeMedia = (values, id) => dispatch => {
    dispatch(loading(true));
    axios.post(`${ROOT_URL_API}/bespoke_media_master/${id}`,
        toFormData({
            accountId: values.accountId,
            name: values.name,
            card: values.card,
            description: values.description,
            estimated_emissions: values.estimated_emissions,
            decarbonise: values.decarbonise,
            topSites: values.topSites,
            uniqueVisitors: values.uniqueVisitors,
            adRequests: values.adRequests,
            audienceFact: values.audienceFact,
            audienceBehavior: values.audienceBehavior,
            dealTypeId: values.dealTypeId,
            categoryIds: values.categoryIds,
            bespoke_status: values.bespoke_status,
            isCustomised: values.isCustomised
        }), { headers: { Authorization: config.authToken() } })
        .then(response => {
            if (response.data && response.data.success) {

                dispatch({ type: AllContextualConstants.UPDATE_BESPOKE_MEDIA_SUCCESS, payload: response.data.data + Date.now() });
            }
            else {
                dispatch({ type: AllContextualConstants.UPDATE_BESPOKE_MEDIA_ERROR, payload: response.data.msg + "~" + Date.now() });
            }

            dispatch(loading(false));
        }).catch(error => {
            dispatch({ type: AllContextualConstants.UPDATE_BESPOKE_MEDIA_ERROR, payload: "Error~" + Date.now() });
            dispatch(loading(false));
        })
}



export const acceptTnC = (id) => dispatch => {
    dispatch(loading(true));
    axios.post(`${ROOT_URL_API}/tnc/${id}`,
        toFormData({
            is_tnc_accepted: true
        }), { headers: { Authorization: config.authToken() } })
        .then(response => {
            dispatch(loading(false));
        }).catch(error => {
            console.log(error)
            dispatch(loading(false));
        })
}
