/* Device code */

export const MOBILE = 0;
export const DESKTOP = 1;
export const MOBILE_APP = 2;
export const VIDEO = 3;

export const getDeviceName = (code) => {
    switch (code) {
        case 0:
        case "0":
            return "Mobile Web";
        case 1:
        case "1":
            return "Desktop";
        case 2:
        case "2":
            return "Mobile App";
        case 3:
        case "3":
            return "Video";
        default:
            return "Mobile Web";
    }
}


export const getDeviceValue = (label) => {
    switch (label) {
        case "Mobile Web":
            return 0;
        case "Desktop":
            return 1;
        case "Mobile App":
            return 2;
        case "Video":
            return 3;
        default:
            return 0;
    }
}

export const getDeviceObject = (code) => {
    switch (code) {
        case 0:
        case "0":
            return { value: 0, label: 'Mobile Web' };
        case 1:
        case "1":
            return { value: 1, label: 'Desktop' };
        case 2:
        case "2":
            return { value: 2, label: 'Mobile App' };
        case 3:
        case "3":
            return { value: 3, label: 'Video' };
        default:
            return { value: 0, label: 'Mobile Web' };
    }
}

export const DeviceDropdown = [{ value: 0, label: 'Mobile Web' }, { value: 1, label: 'Desktop' }, { value: 2, label: 'Mobile App' }, { value: 3, label: 'Video' }]

export const Devices = [{ value: "1", label: 'Desktop' }, { value: "0", label: 'Mobile Web' }, { value: "2", label: 'Mobile App' }, { value: "3", label: 'Video' }]