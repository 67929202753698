import { format } from "date-fns"
import { pushLocation } from "../utilities/customRoutes"
import { getQueryStringParams } from "../utilities/customFunctions"
export const COMMERCIAL_RATES = "/commercials/rates"


export const gotoRates = (id = "") => {
  pushLocation(`${COMMERCIAL_RATES}${id ? `/${id}` : ""}`)
}
export const renderStatusBadge = (status) => {
  return (<div className={`enterprise-badge badge-${status?.toLowerCase()?.replace(/\s+/g, '-')}`}>{status}</div>)
}

export const RenderEnterpriseLogo = (props) => <div id="bb-buddy-logo-small" style={{ width: "185px" }} className={"img-fluid bb-buddy-logo-small cursor-pointer mr-3 " + props.model} />


export const getModel = (location, redirect = true) => {
  let searchParams = getQueryStringParams(location.search)
  if (searchParams.model) {
    return searchParams.model
  }
  else if (redirect) { pushLocation("/commercials") } else { return "" }

}

export const getModelNum = (label) => {
  switch (label) {
    case "independent":
      return 1;
    case "unified-rewards":
      return 2;
    case "unified-discount":
      return 3;
    default:
      return 1;
  }
}


export function createCPMObject(num) {
  return {
    label: num,
    value: num,
    name: `$${num} CPM`
  };
}

export function generateCPMArray(min, max, text = "CPM", prefix = "$") {
  let result = [];

  for (let i = min; i <= max; i++) {
    result.push({
      label: i,
      value: i,
      name: `${prefix}${i} ${text}`
    });
  }

  return result;
}
export const PREMIUM_CPM = generateCPMArray(20, 50)

export const SUPPORT_OPTIONS = [
  {
    "label": "Standard Package - Free",
    "value": "Standard Package - Free"
  },
  {
    "label": "Foundation Package - $10,000/month",
    "value": "Foundation Package - $10,000/month"
  },
  {
    "label": "Growth Package - $15,000/month",
    "value": "Growth Package - $15,000/month"
  },
  {
    "label": "Elite Package - $20,000/month",
    "value": "Elite Package - $20,000/month"
  }
]


export const enterpriseMenu = (model, reset = false) => {
  let refundBlock = document.getElementById("1077")
  let savingsBlock = document.getElementById("1177")
  let marginBlock = document.getElementById("1277")
  let feesBlock = document.getElementById("1188")
  let spendBlock = document.getElementById("1377")
  if (!reset) {
    if (model === 1) {
      feesBlock.style.display = "block";
    } else if (model === 2) {
      refundBlock.style.display = "block";
    } else {
      savingsBlock.style.display = "block";
      marginBlock.style.display = "block";
    }
    spendBlock.style.display = "block"
  }
  else {
    if (feesBlock) feesBlock.style.display = "none";
    if (refundBlock) refundBlock.style.display = "none";
    if (savingsBlock) savingsBlock.style.display = "none";
    if (marginBlock) marginBlock.style.display = "none";
    if (spendBlock) spendBlock.style.display = "none";
  }
}

export function roundDownToNearestHalf(num) {
  return roundDownToNearestTenth(num)

}

function roundDownToNearestTenth(num) {
  // Multiply by 10, floor, then divide by 10
  const rounded = Math.round(num * 10) / 10;
  // Convert to a string with exactly two decimal places
  return rounded;
}

export const getAdjustedCPM = (inputModel, monthlySpendBand, premiumCPM, advancedCPM, country = "AU", data) => {
  const premiumPerc = getSinglePercentage(inputModel, monthlySpendBand, premiumCPM, country, data);
  const advancedPerc = getSinglePercentage(inputModel, monthlySpendBand, advancedCPM, country, data);

  const adjusted_cpm = {
    prem: roundDownToNearestHalf((((100 - premiumPerc) * premiumCPM) / 100)).toFixed(2),
    adv: roundDownToNearestHalf((((100 - advancedPerc) * advancedCPM) / 100)).toFixed(2)
  }
  return adjusted_cpm;
}
export const getPercentagePaid = (inputModel, monthlySpendBand, premiumCPM, advancedCPM, country = "AU", data) => {


  // Filter items for premium and advanced CPM matches
  const filterByCPM = (cpm) =>
    data.filter(
      (item) =>
        item.model === inputModel &&
        item.monthly_spend_band === monthlySpendBand &&
        item.combined_cpm === cpm &&
        item.country === country
    );

  const premiumMatches = filterByCPM(premiumCPM);
  const advancedMatches = filterByCPM(advancedCPM);

  // Extract and parse percentage values from matched items
  const percentages = [
    ...premiumMatches.map((item) => item.percentage),
    ...advancedMatches.map((item) => item.percentage)
  ].filter((p) => !isNaN(p)); // filter out any NaN values

  // If no matching percentages found, return null or a default value
  if (percentages.length === 0) {
    return null;
  }



  // Determine result based on model type
  if (inputModel.toLowerCase().includes('unified')) {
    const minValue = Math.min(...percentages);
    return `${minValue.toFixed(2)}%`;
  } else {
    const maxValue = Math.max(...percentages);
    return `${maxValue.toFixed(2)}%`;
  }
}

function getSinglePercentage(model, monthlySpendBand, cpm, country = "AU", data) {
  const matches = data.filter(item =>
    item.model === model &&
    item.monthly_spend_band === monthlySpendBand &&
    item.combined_cpm === cpm &&
    item.country === country
  );

  const percentages = matches
    .map(item => item.percentage)
    .filter(p => !isNaN(p));

  if (percentages.length === 0) return null;
  // Use min for "Unified" model, max otherwise
  if (model.toLowerCase().includes('unified')) {
    return Math.min(...percentages);
  } else {
    return Math.max(...percentages);
  }
}

export const calculateSaasFees = (model, premiumMonthlySpend, advancedMonthlySpend, premiumCPM, advancedCPM, country = "AU", data) => {


  // Calculate total spend
  const totalSpend = premiumMonthlySpend + advancedMonthlySpend;

  // Find all records matching the model and where totalSpend fits the spend band
  const possibleBands = data.filter(item =>
    item.model === model &&
    item.min_spend_band <= totalSpend &&
    item.max_spend_band >= totalSpend &&
    item.country === country
  );

  // Extract unique monthly spend bands from the filtered records
  const bands = [...new Set(possibleBands.map(item => item.monthly_spend_band))];
  if (bands.length === 0) {
    console.error("No matching monthly spend band found.");
    return null;
  }
  // Assume the first matching band is correct
  const monthlySpendBand = bands[0];
  // Retrieve percentages for premium and advanced using the same band
  const premiumPerc = getSinglePercentage(model, monthlySpendBand, premiumCPM, country, data);
  const advancedPerc = getSinglePercentage(model, monthlySpendBand, advancedCPM, country, data);

  if (premiumPerc === null || advancedPerc === null) {
    console.error("Percentages not found for provided CPMS and model.");
    return null;
  }

  // Calculate SaaS fees
  const premiumSaasFee = (premiumPerc / 100) * premiumMonthlySpend;
  const advancedSaasFee = (advancedPerc / 100) * advancedMonthlySpend;
  const totalMonthlySpend = premiumMonthlySpend + advancedMonthlySpend;
  const totalSaasFee = premiumSaasFee + advancedSaasFee;

  // Calculate remaining SaaS fee and percentage paid
  const saasFee = model.toLowerCase().includes('unified') ? totalSaasFee : (totalMonthlySpend - totalSaasFee);
  const bigadsSaasFee = model.toLowerCase().includes('unified') ? (totalMonthlySpend - totalSaasFee) : totalSaasFee
  const percentagePaid = (saasFee / totalMonthlySpend) * 100;

  return {
    premiumSaasFee,
    advancedSaasFee,
    saasFee,
    percentagePaid: Number(percentagePaid).toFixed(2),
    monthlySpendBand,
    totalSaasFee: bigadsSaasFee,
    totalSaasFeePercentage: (100 - Number(percentagePaid).toFixed(2)).toFixed(2),
    annual_fee: 12 * bigadsSaasFee,
    annual_earnings: 12 * saasFee,
    total_predicted_spend: totalSpend
  };
}


export const calculateDiscounts = (premiumMonthlySpend, advancedMonthlySpend, premiumCPM, advancedCPM, country = "AU", data) => {
  // Calculate total spend
  const totalSpend = premiumMonthlySpend + advancedMonthlySpend;
  let model = "unified-discount"
  // Find all records matching the model and where totalSpend fits the spend band
  const possibleBands = data.filter(item =>
    item.model === model &&
    item.min_spend_band <= totalSpend &&
    item.max_spend_band >= totalSpend &&
    item.country === country
  );

  const imp_purchased = {
    prem: Math.round((premiumMonthlySpend / premiumCPM) * 1000),
    adv: Math.round((advancedMonthlySpend / advancedCPM) * 1000)
  }

  // Extract unique monthly spend bands from the filtered records
  const bands = [...new Set(possibleBands.map(item => item.monthly_spend_band))];
  if (bands.length === 0) {
    console.error("No matching monthly spend band found.");
    return {
      imp_purchased,
      vol_discount: { prem: 0, adv: 0 },
      adjusted_cpm: { prem: premiumCPM, adv: advancedCPM },
      imp_increase: { prem: 0, adv: 0 },
      adjusted_imp: {
        prem: Math.round((premiumMonthlySpend / premiumCPM) * 1000),
        adv: Math.round((advancedMonthlySpend / advancedCPM) * 1000)
      },
      total_predicted_spend: totalSpend
    };
  }
  // Assume the first matching band is correct
  const monthlySpendBand = bands[0];
  // Retrieve percentages for premium and advanced using the same band
  const premiumPerc = getSinglePercentage(model, monthlySpendBand, premiumCPM, country, data);
  const advancedPerc = getSinglePercentage(model, monthlySpendBand, advancedCPM, country, data);

  if (premiumPerc === null || advancedPerc === null) {
    console.error("Percentages not found for provided CPMS and model.");
    return null;
  }


  const vol_discount = {
    prem: premiumMonthlySpend > 0 ? premiumPerc.toFixed(2) : 0,
    adv: advancedMonthlySpend > 0 ? advancedPerc.toFixed(2) : 0
  }
  const adjusted_cpm = {
    prem: premiumMonthlySpend > 0 ? roundDownToNearestHalf((((100 - premiumPerc) * premiumCPM) / 100)).toFixed(2) : 0,
    adv: advancedMonthlySpend > 0 ? roundDownToNearestHalf((((100 - advancedPerc) * advancedCPM) / 100)).toFixed(2) : 0
  }

  const imp_increase = {
    prem: Math.round((premiumMonthlySpend * (premiumCPM - adjusted_cpm.prem) / (premiumCPM * adjusted_cpm.prem)) * 1000),
    adv: Math.round((advancedMonthlySpend * (advancedCPM - adjusted_cpm.adv) / (advancedCPM * adjusted_cpm.adv)) * 1000),
  }

  const adjusted_imp = {
    prem: Math.round((premiumMonthlySpend / adjusted_cpm.prem) * 1000),
    adv: Math.round((advancedMonthlySpend / adjusted_cpm.adv) * 1000),
  }

  return {
    imp_purchased,
    vol_discount,
    adjusted_cpm,
    imp_increase,
    adjusted_imp,
    total_predicted_spend: totalSpend
  };
}


export const calculateRefundRate = (premiumMonthlySpend, advancedMonthlySpend, premiumCPM, advancedCPM, country = "Australia", data) => {
  // Calculate total spend
  const totalSpend = premiumMonthlySpend + advancedMonthlySpend;
  let model = "unified-rewards"
  // Find all records matching the model and where totalSpend fits the spend band
  const possibleBands = data.filter(item =>
    item.model === model &&
    item.min_spend_band <= totalSpend &&
    item.max_spend_band >= totalSpend &&
    item.country === country
  );

  // Extract unique monthly spend bands from the filtered records
  const bands = [...new Set(possibleBands.map(item => item.monthly_spend_band))];
  if (bands.length === 0) {
    console.error("No matching monthly spend band found.");
    return {
      adjusted_cpm: { prem: premiumCPM, adv: advancedCPM },
      total_predicted_spend: totalSpend
    };
  }
  // Assume the first matching band is correct
  const monthlySpendBand = bands[0];
  // Retrieve percentages for premium and advanced using the same band
  const premiumPerc = getSinglePercentage(model, monthlySpendBand, premiumCPM, country, data);
  const advancedPerc = getSinglePercentage(model, monthlySpendBand, advancedCPM, country, data);

  if (premiumPerc === null || advancedPerc === null) {
    console.error("Percentages not found for provided CPMS and model.");
    return null;
  }

  const adjusted_cpm = {
    prem: premiumMonthlySpend > 0 ? roundDownToNearestHalf((((100 - premiumPerc) * premiumCPM) / 100)).toFixed(2) : 0,
    adv: advancedMonthlySpend > 0 ? roundDownToNearestHalf((((100 - advancedPerc) * advancedCPM) / 100)).toFixed(2) : 0
  }

  return {
    adjusted_cpm,
    total_predicted_spend: totalSpend
  };
}


export const getCountry = (id) => {
  if (id === 15) {
    return "AU"
  }
  else if (id === 153) {
    return "NZ"

  }
  else {
    return false
  }

}


export const generatePDF = (modelL, model, values, generateTcs, companyId) => {
  let obj = {
    "model": modelL,
    "date": `${format(new Date(values.startDate), "dd/MM/yyyy")} - ${format(new Date(values.endDate), "dd/MM/yyyy")}`,
    "company_name": typeof values?.company_name === "object" ? values?.company_name.name : values?.company_name || "",
    "monthly_spend_band": values?.monthly_spend?.label || "",
    "bigAdsS": values?.bigads_approver || "",
    "bigAdsSD": format(new Date(values.bigads_approved_date), "dd/MM/yyyy"),
    "userS": typeof values?.commercials_approver === "object" ? values?.commercials_approver.firstName + " " + values?.commercials_approver.lastName: values?.commercials_approver || "",
    "userSD": format(new Date(values.commercials_approved_date), "dd/MM/yyyy"),
    "company_id": companyId
  }
  if (model === 1) {
    obj = {
      ...obj,
      "minimum_cpm_rate": `$${values?.premium_spend?.label} for Premium and $${values?.advanced_spend?.label} for Advanced format`,
      "discount_or_percentage": `${values?.percentage}`,
    }
  }
  else if (model === 2) {
    obj = {
      ...obj,
      "minimum_cpm_rate": `$${values?.set_adjusted_cpm_prem} for Premium and $${values?.set_adjusted_cpm_adv} for Advanced format`,
      "discount_or_percentage": `${values?.percentage_refund}`,
      "client_facing_cpm_rate": `$${values?.premium_spend_set?.label} for Premium and $${values?.advanced_spend_set?.label} for Advanced format`
    }
  }
  else {
    obj = {
      ...obj,
      "minimum_cpm_rate": `$${Math.min(values?.premium_spend_set?.label, values?.advanced_spend_set?.label)}`,
      "discount_or_percentage": `${values?.percentage_discount || ""}`,
    }
  }
  generateTcs(obj)

}

export const formatSubmitDate = (date) => {
  return `${format(new Date(date), "yyyy-MM-dd")}`

}

export const getSumbitValues = (values, modelL, model, user, tcs, status, country, approved, isEdit) => {
  if (isEdit) {
    let obj = { ...values }
    obj["status"] = status
    obj["bigads_approved_date"] = approved["bigads_approved_date"]
    obj["bigads_approver_id"] = approved["bigads_approver_id"] 
    obj["bigads_approver"] = approved["bigads_approver"] 

    obj["approved_date"] = approved["approved_date"]
    obj["approver_id"] = approved["approver_id"] 
    obj["approver"] = approved["approver"] 

    return obj;
  }

  let v = {
    "model": modelL,
    "company_id": typeof values?.company_name === "object" ? values?.company_name.id : user.networks,
    "company_name": typeof values?.company_name === "object" ? values?.company_name.name : user.networkName,
    "agreement_period": {
      "start_date": formatSubmitDate(values.startDate),
      "end_date": formatSubmitDate(values.endDate)
    },
    "monthly_spend_band": values.monthly_spend.label,
    
    "approver": approved["approver"],
    "approved_date": status === "DRAFT"  || status === "PENDING CLIENT APPROVAL" ? "" : approved.approved_date,
    "approver_id": approved["approver_id"], 
    
    "bigads_approver": approved["bigads_approver"] ,
    "bigads_approver_id": approved["bigads_approver_id"] , 
    "bigads_approved_date": status === "DRAFT" || status === "PENDING BIGADS APPROVAL"  ? "" : approved.bigads_approved_date,
    
    "tcs_url": tcs,
    "review_dates": [formatSubmitDate(values.first_spend_date), formatSubmitDate(values.second_spend_date), formatSubmitDate(values.third_spend_date), formatSubmitDate(values.fourth_spend_date)],
    "status": status,
    "country": country
  }
  if (status === "DRAFT") {
    v["requested_by"] = `${user.firstName} ${user.lastName}`;
    v["requested_on"] = formatSubmitDate(new Date());

  }
  if (model === 1) {
    v = {
      ...v,
      "agree_to_use_criteo": values?.ssp_publisher_enabled,
      "agree_to_grant_criteo_access": values?.criteo_set_enabled,
      "client_facing_cpm": {
        "prem": values?.premium_spend?.label,
        "adv": values?.advanced_spend?.label
      },
      "sass_fee": values?.percentage,
      "support_options": values?.support_options?.label,
    }
  }
  else if (model === 2) {
    v = {
      ...v,
      "receive_publisher_statement": values?.receive_enabled || false,
      "receive_publisher_statement_email": values?.email || "",
      "managed_service": values?.managed_service || false,
      "standard_cpm_rate": {
        "prem": values.premium_spend?.label,
        "adv": values.advanced_spend?.label
      },
      "discounted_cpm_rate": {
        "prem": values?.set_adjusted_cpm_prem,
        "adv": values?.set_adjusted_cpm_adv
      },
      "client_facing_cpm": {
        "prem": values?.premium_spend_set.label,
        "adv": values?.advanced_spend_set.label
      },
      "refund_percentage": values?.percentage_refund,
    }
  }
  else {
    v = {
      ...v,
      "receive_publisher_statement": values?.receive_enabled || false,
      "receive_publisher_statement_email": values?.email || "",
      "managed_service": values?.managed_service || false,
      "standard_cpm_rate": {
        "prem": values?.premium_spend?.label || "",
        "adv": values?.advanced_spend?.label || ""
      },
      "discounted_cpm_rate": {
        "prem": values?.set_adjusted_cpm_prem || "",
        "adv": values?.set_adjusted_cpm_adv || ""
      },
      "client_facing_cpm": {
        "prem": values?.premium_spend_set?.label || "",
        "adv": values?.advanced_spend_set?.label || ""
      },
      "discount_percentage": values?.percentage_discount || ""
    }
  }

  return v;
}


export const calculateFees = (values, setFieldValue, setShowCalculation, calculateSaasFees, modelL, totalSpendSumCheck, country, CMPModel) => {

  const { premium_spend_2, advanced_spend_2, monthly_spend_premium, monthly_spend_advanced } = values
  if (premium_spend_2 && advanced_spend_2 && ((monthly_spend_premium + monthly_spend_advanced) >= totalSpendSumCheck)) {
    let fees = calculateSaasFees(modelL, Number(monthly_spend_premium), Number(monthly_spend_advanced), premium_spend_2.value, advanced_spend_2.value, country, CMPModel?.data || [])

    setFieldValue("usage_saas_fee_percentage", fees.percentagePaid)
    setFieldValue("bigads_saas_fee_percentage", fees.totalSaasFeePercentage)
    setFieldValue("bigads_saas_fee", fees.totalSaasFee)
    setFieldValue("paid_to_client", fees.saasFee)
    setFieldValue("annual_earnings", fees.annual_earnings)
    setFieldValue("annual_fee", fees.annual_fee)
    setFieldValue("total_predicted_spend", fees.total_predicted_spend)
    setShowCalculation(true)
  }
}

export const handleSetSellCPM = async (setFieldValue, setPrem_spend_set, setAdv_spend_set, setShowDiscountMargin, handleCalculation, formik, model) => {

  const { premium_spend_set, advanced_spend_set } = formik?.current?.values
  setPrem_spend_set(premium_spend_set)
  setAdv_spend_set(advanced_spend_set)

  if (premium_spend_set || advanced_spend_set) {
    setShowDiscountMargin(true)

    if (model === 2 || model === 3) {
      handleCalculation(setFieldValue)
    }
  }
}

export const calculateDiscountFees = async (values, setFieldValue, totalSpendSumCheck, country, CMPModel, setShowCalculation, showDiscountMargin, calculateMargin) => {
  const { prem_spend, adv_spend, monthly_spend_prem, monthly_spend_adv } = values

  if (prem_spend && adv_spend && ((monthly_spend_prem + monthly_spend_adv) >= totalSpendSumCheck)) {

    let fees = calculateDiscounts(Number(monthly_spend_prem), Number(monthly_spend_adv), prem_spend.value, adv_spend.value, country, CMPModel?.data || [])

    await setFieldValue("imp_purchased_prem", fees.imp_purchased.prem)
    await setFieldValue("imp_purchased_adv", fees.imp_purchased.adv)

    await setFieldValue("vol_discount_percentage_prem", fees.vol_discount.prem)
    await setFieldValue("vol_discount_percentage_adv", fees.vol_discount.adv)

    await setFieldValue("adjusted_cpm_prem", fees.adjusted_cpm.prem)
    await setFieldValue("adjusted_cpm_adv", fees.adjusted_cpm.adv)

    await setFieldValue("imp_increase_prem", fees.imp_increase.prem)
    await setFieldValue("imp_increase_adv", fees.imp_increase.adv)

    await setFieldValue("adjusted_imp_prem", fees.adjusted_imp.prem)
    await setFieldValue("adjusted_imp_adv", fees.adjusted_imp.adv)



    setShowCalculation(true)
    if (showDiscountMargin) {
      calculateMargin(setFieldValue)
    }
  }
}


export const calculateRefundRates = async (values, setFieldValue, totalSpendSumCheck, calculateRefund, CMPModel, country) => {
  const { prem_spend, adv_spend, monthly_spend_prem, monthly_spend_adv } = values

  if (prem_spend && adv_spend && ((monthly_spend_prem + monthly_spend_adv) >= totalSpendSumCheck)) {

    let fees = calculateRefundRate(Number(monthly_spend_prem), Number(monthly_spend_adv), prem_spend.value, adv_spend.value, country, CMPModel?.data || [])

    await setFieldValue("adjusted_cpm_prem", fees.adjusted_cpm.prem)
    await setFieldValue("adjusted_cpm_adv", fees.adjusted_cpm.adv)

    calculateRefund(setFieldValue)

  }
}

export const setEditVals = (sfv, profile) => {
  sfv("company_name", profile?.company_name)
  sfv("startDate", new Date(profile?.agreement_period?.start_date  || ""))
  sfv("endDate", new Date(profile?.agreement_period?.end_date  || ""))
  sfv("first_spend_date", new Date(profile?.review_dates[0] || ""))
  sfv("second_spend_date", new Date(profile?.review_dates[1] || ""))
  sfv("third_spend_date", new Date(profile?.review_dates[2] || ""))
  sfv("fourth_spend_date", new Date(profile?.review_dates[3] || ""))
  sfv("commercials_approver", profile?.approver)
  sfv("commercials_approved_date", profile?.approved_date ? new Date(profile?.approved_date) : new Date())
  profile?.bigads_approver && sfv("bigads_approver", profile?.bigads_approver)
  sfv("bigads_approved_date", profile?.bigads_approved_date ? new Date(profile?.bigads_approved_date) : new Date())
  sfv("monthly_spend", profile?.monthly_spend_band)
  sfv("support_options", profile?.support_options || false)


  sfv("ssp_publisher_enabled", profile?.agree_to_use_criteo || false)
  sfv("criteo_set_enabled", profile?.agree_to_grant_criteo_access || false)


  sfv("receive_enabled", profile?.receive_publisher_statement || false)
  sfv("premium_spend", profile?.model === "independent" ? profile?.client_facing_cpm?.prem : profile?.standard_cpm_rate.prem)
  sfv("advanced_spend", profile?.model === "independent" ? profile?.client_facing_cpm?.adv : profile?.standard_cpm_rate.adv)

  sfv("premium_spend_set", profile?.client_facing_cpm?.prem || 0)
  sfv("advanced_spend_set", profile?.client_facing_cpm?.adv || 0)


  sfv("percentage", profile?.sass_fee || 0)
  sfv("email", profile?.receive_publisher_statement_email || false)

  sfv("percentage_refund", profile?.refund_percentage || 0)
  sfv("percentage_discount", profile?.discount_percentage || 0)



  sfv("set_adjusted_cpm_prem", profile?.discounted_cpm_rate?.prem || 0)
  sfv("set_adjusted_cpm_adv", profile?.discounted_cpm_rate?.adv || 0)
  sfv("managed_service", profile?.managed_service || false)



}
